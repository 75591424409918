import { IPaginate, IParams } from 'shared/interfaces';

import api from 'services/api';

import { IPermission } from './interfaces';

const getPermissions = async (
  params: IParams,
): Promise<IPaginate<IPermission>> => {
  const response = await api.get<IPaginate<IPermission>>('/permissions', {
    params,
  });

  return response.data;
};

export { getPermissions };
