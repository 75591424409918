import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { MdSearch } from 'react-icons/md';
import { FaTags } from 'react-icons/fa';

import {
  InputSearch,
  ButtonLink,
  HeaderBackground,
  HeaderPage,
  HeaderInfo,
  Table,
  Pagination,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Position,
  Button,
  Loader,
  TableResponsive,
  PopUpMenu,
} from 'components';

import { ModalHandles } from 'components/Modal';

import api from 'services/api';
import { useToast } from 'contexts/ToastProvider';

import * as S from './styles';

interface Report {
  id: string;
  name: string;
  category_id: string;
  category: { name: string };
  wordkspace_id: string;
  report_id: string;
  report_name: string;
  rls: boolean;
}

interface Paginate {
  data: Report[];
  page: number;
  per_page: number;
  last_page: number;
  total: number;
}

const ReportList: React.FC = () => {
  const [reports, setReports] = useState<Paginate>();
  const [selectedReport, setSelectedReport] = useState<Report>();
  const modalRemoverRef = useRef<ModalHandles>(null);

  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState('');
  const { addToast } = useToast();
  const history = useHistory();

  const handleSearch = useCallback(
    async ({ page, per_page }) => {
      try {
        setLoading(true);

        const params = {
          page,
          per_page,
          term,
        };

        const response = await api.get<Paginate>('/reports', { params });

        const { data } = response;

        setReports(data);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ops...',
          description: 'Ops, ocorreu um erro no processo de pesquisa',
        });
      } finally {
        setLoading(false);
      }
    },
    [term, addToast],
  );

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 10,
    };

    handleSearch(params);
  }, [handleSearch]);

  const handleRemove = async (): Promise<void> => {
    try {
      await api.delete(`/reports/${selectedReport?.id}`);

      if (!reports) return;
      const newList = reports.data.filter(
        (item) => item.id !== selectedReport?.id,
      );

      setReports({ ...reports, data: newList });

      modalRemoverRef.current?.closeModal();

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Relatorio removido com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: 'Ocorreu um erro ao inserir um novo registro',
      });
    }
  };

  const handleOpenModalRemove = useCallback((reportSelected: Report): void => {
    setSelectedReport(reportSelected);
    modalRemoverRef.current?.openModal();
  }, []);

  return (
    <S.Container>
      <HeaderBackground>
        <HeaderPage>
          <HeaderInfo title="Relatórios" icon={FaTags} />

          <S.ContainerSearch>
            <InputSearch
              title="Digite aqui o que deseja pesquisar"
              icon={MdSearch}
              placeholder="Pesquisar"
              onChange={(e) => setTerm(e.target.value)}
              onKeyDown={(e) =>
                e.key === 'Enter'
                  ? handleSearch({ page: 1, per_page: reports?.per_page })
                  : {}
              }
            />
          </S.ContainerSearch>

          <div>
            <ButtonLink title="Adicionar relatório" to="/reports/add">
              Adicionar
            </ButtonLink>
          </div>
        </HeaderPage>
      </HeaderBackground>

      <S.Panel>
        {loading ? (
          <Loader loading={loading} align="center" message="Carregando..." />
        ) : (
          <>
            <TableResponsive>
              <Table>
                <thead>
                  <tr>
                    <th className="text-left">Relatório</th>
                    <th className="text-left">Relatório PBI</th>
                    <th className="text-left">Categoria</th>
                    <th className="text-center">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {reports &&
                    reports.data.map((item: Report) => (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{item.report_name}</td>
                        <td>{item.category.name}</td>
                        <td className="text-center">
                          <PopUpMenu>
                            <button
                              type="button"
                              onClick={() =>
                                history.push(`/reports/edit/${item.id}`)
                              }
                            >
                              <S.IconEdit
                                title="Editar"
                                size={24}
                                color="#707070"
                              />
                              Editar
                            </button>

                            <button
                              type="button"
                              onClick={() => handleOpenModalRemove(item)}
                            >
                              <S.IconDelete
                                title="Remover"
                                size={24}
                                color="#707070"
                              />
                              Remover
                            </button>
                          </PopUpMenu>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </TableResponsive>
            <S.FooterContainer>
              {reports && (
                <Pagination
                  page={reports?.page}
                  total={reports?.total}
                  per_page={reports?.per_page}
                  last_page={reports?.last_page}
                  onSearch={handleSearch}
                />
              )}
            </S.FooterContainer>
          </>
        )}
      </S.Panel>

      <Modal ref={modalRemoverRef} options={{ width: '400px' }}>
        <ModalHeader>
          <ModalTitle>Você Confirma a EXCLUSÃO?</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>O registro será removido completamente!</p>
        </ModalBody>
        <ModalFooter>
          <Position align="right">
            <Button
              variant="outline-secondary"
              onClick={() => modalRemoverRef.current?.closeModal()}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => handleRemove()}
              style={{ marginLeft: '16px' }}
            >
              SIM, confirmo a exclusão
            </Button>
          </Position>
        </ModalFooter>
      </Modal>
    </S.Container>
  );
};

export default ReportList;
