import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaTags } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import { OptionTypeBase } from 'react-select';

import { onDelay } from 'utils/debounce';

import { IRouterParams, IOptionReactSelect } from 'shared/interfaces';

import { useToast } from 'contexts/ToastProvider';

import * as C from 'components';

import {
  getWorkspace,
  getEmbeddedWorkspaces,
  createWorkspace,
  updateWorkspace,
} from '../api';

import { IWorkspace, IWorkspaceForm } from '../interfaces';

import * as S from './styles';

const schema = Yup.object().shape({
  name: Yup.string().required('Workspace obrigatório'),
  workspace_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('ID Workspace obrigatorio')
    .typeError('ID Workspace obrigatorio'),
  // workspace_id: Yup.string().required('Id do workspace obrigatório'),
});

const WorkspaceForm: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const { id } = useParams<IRouterParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [workspace, setWorkspace] = useState<IWorkspace>();

  const onSubmit = useCallback(
    async (data: IWorkspaceForm) => {
      try {
        setLoading(true);

        if (id) {
          const response = await updateWorkspace(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/workspaces');
          }
        } else {
          await createWorkspace(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/workspaces');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, history, id, reset],
  );

  const loadOptionsWorkspace = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getEmbeddedWorkspaces(inputValue).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  useEffect(() => {
    if (id) {
      getWorkspace(id).then((data) => {
        reset(data);
        setWorkspace(data);
      });
    }
  }, [id, reset]);

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/workspaces">
              <MdKeyboardBackspace size={24} color="#fff" />
              Workspaces
            </Link>
          </S.BackLink>
          <C.HeaderInfo
            title={id ? `Editando: ${workspace?.name}` : 'Nova Workspace'}
            icon={FaTags}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <C.FormGroup>
            <C.Label>Workspace</C.Label>

            <C.Input
              {...register('name')}
              type="text"
              errors={errors.name}
              name="name"
              placeholder="Workspace"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Worspace PBI</C.Label>
            <C.ReactSelectAsync
              {...register('workspace_id')}
              control={control}
              errors={errors.workspace_id}
              loadOptions={loadOptionsWorkspace}
              isClearable
              isSearchable
              loadingMessage="Buscando..."
              noOptionsMessage="Nenhum registro localizado"
            />
          </C.FormGroup>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default WorkspaceForm;
