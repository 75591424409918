import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaTags } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';

import { OptionTypeBase } from 'react-select';

import { onDelay } from 'utils/debounce';

import { IRouterParams, IOptionReactSelect } from 'shared/interfaces';

import { useToast } from 'contexts/ToastProvider';

import { getWorkspaces } from 'pages/Workspace/api';
import { getCategories } from 'pages/Category/api';
import {
  getReport,
  getEmbeddedReport,
  createReport,
  updateReport,
} from 'pages/Report/api';

import * as C from 'components';
import * as S from './styles';

import { IReport, IReportForm } from '../interfaces';

const schema = Yup.object().shape({
  name: Yup.string().required('Relatório obrigatório'),
  category_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Categoria obrigatoria')
    .typeError('Catgoria obrigatoria'),
  workspace_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Workspace obrigatorio')
    .typeError('Workspace obrigatorio'),
  report_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('ID Report obrigatorio')
    .typeError('ID Report obrigatorio'),
});

const ReportForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState<IReport>();
  const { id } = useParams<IRouterParams>();
  const history = useHistory();

  const workspaceId: IOptionReactSelect = watch('workspace_id') || undefined;

  const loadOptionsCategories = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getCategories({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsWorkspaces = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getWorkspaces({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsReports = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getEmbeddedReport({
        workspace_id: workspaceId.value,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const onSubmit = useCallback(
    async (data: IReportForm) => {
      try {
        setLoading(true);

        if (id) {
          const response = await updateReport(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/reports');
          }
        } else {
          await createReport(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/reports');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, history, id, reset],
  );

  useEffect(() => {
    if (id) {
      getReport(id).then((data) => {
        reset(data);
        setReport(data);
      });
    }
  }, [id, reset]);

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/reports">
              <MdKeyboardBackspace size={24} color="#fff" />
              Relatórios
            </Link>
          </S.BackLink>
          <C.HeaderInfo
            title={id ? `Editando: ${report?.name}` : 'Novo Relatório'}
            icon={FaTags}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <C.FormGroup>
            <C.Label>Relatório</C.Label>
            <C.Input
              {...register('name')}
              type="text"
              errors={errors.name}
              name="name"
              placeholder="Relatório"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Categoria</C.Label>
            <C.ReactSelectAsync
              {...register('category_id')}
              control={control}
              errors={errors.category_id}
              loadOptions={loadOptionsCategories}
              isClearable
              isSearchable
              loadingMessage="Buscando..."
              noOptionsMessage="Nenhum registro localizado"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Worspace</C.Label>
            <C.ReactSelectAsync
              {...register('workspace_id')}
              control={control}
              errors={errors.workspace_id}
              loadOptions={loadOptionsWorkspaces}
              isClearable
              isSearchable
              loadingMessage="Buscando..."
              noOptionsMessage="Nenhum registro localizado"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Report PBI</C.Label>
            <C.ReactSelectAsync
              {...register('report_id')}
              control={control}
              errors={errors.report_id}
              loadOptions={loadOptionsReports}
              isClearable
              isSearchable
              loadingMessage="Buscando..."
              noOptionsMessage="Nenhum registro localizado"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Checkbox
              {...register('rls')}
              name="rls"
              items={[{ id: 1, name: 'Possui RLS?' }]}
            />
          </C.FormGroup>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default ReportForm;
