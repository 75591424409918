import { IPaginate, IParams } from 'shared/interfaces';

import api from 'services/api';

import {
  IReport,
  IEmbeddedReport,
  IReportForm,
  IParamsReport,
} from './interfaces';

const createFormDataReport = (report: IReportForm) => {
  const data = {
    ...report,
    rls: report.rls ? '1' : '0',
    category_id: report.category_id?.value || null,
    workspace_id: report.workspace_id?.value || null,
    report_id: report.report_id?.value || null,
    report_name: report.report_id?.label || null,
  };

  return data;
};

const getReport = async (id: string): Promise<IReport> => {
  const report = await api.get<IReport>(`/reports/${id}`);

  const data: IReport = {
    ...report.data,
    workspace_id: report.data?.workspace.id
      ? {
          label: String(report.data?.workspace.name) || '',
          value: String(report.data?.workspace.id) || '',
        }
      : '',
    category_id: report.data?.category.id
      ? {
          label: String(report.data?.category.name) || '',
          value: String(report.data?.category.id) || '',
        }
      : '',
    report_id: report.data?.report_id
      ? {
          label: String(report.data?.report_name) || '',
          value: String(report.data?.report_id) || '',
        }
      : '',
  };

  return data;
};

const getReports = async (params: IParams): Promise<IPaginate<IReport>> => {
  const response = await api.get<IPaginate<IReport>>('/reports', {
    params,
  });

  return response.data;
};

const createReport = async (report: IReportForm): Promise<void> => {
  const data = createFormDataReport(report);

  await api.post('/reports', data);
};

const updateReport = async (
  id: string,
  report: IReportForm,
): Promise<IReport> => {
  const data = createFormDataReport(report);

  const response = await api.put<IReport>(`/reports/${id}`, data);

  return response.data;
};

const deleteReport = async (id: string): Promise<void> => {
  await api.delete(`/reports/${id}`);
};

const getEmbeddedReport = async (
  params: IParamsReport,
): Promise<IEmbeddedReport> => {
  const embeddedWorkspace = await api.get<IEmbeddedReport>(
    `/embedded/group/reports/${params.workspace_id}`,
    { params },
  );

  return embeddedWorkspace.data;
};

export {
  getReport,
  getReports,
  createReport,
  updateReport,
  deleteReport,
  getEmbeddedReport,
};
