import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Form from './Form';

const User: React.FC = () => {
  return (
    <Switch>
      <Route path="/users" component={List} isPrivate exact />
      <Route path="/users/add" component={Form} isPrivate />
      <Route path="/users/edit/:id" component={Form} isPrivate />
    </Switch>
  );
};

export default User;
