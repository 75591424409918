import {
  ISignIn,
  ISignInFastmedic,
} from '../../../pages/SignIn/interfaces/index';
import api from '../../api';

export const singIn = async ({ email, password }: ISignIn) => {
  return api
    .post('sessions', { email, password })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const singInFastmedic = async ({
  login,
  password,
}: ISignInFastmedic) => {
  return api
    .post('sessions/fastmedic', {
      municipio: process.env.REACT_APP_MUNICIPIO || '0',
      dominio: process.env.REACT_APP_DOMINIO || 'fastmedic.com.br',
      login,
      password,
      tenant_id: process.env.REACT_APP_TENANT,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const changeAdminUser = async (user_id: string) => {
  const response = await api.get(`users/change-admin-view/${user_id}`);

  return response.data;
};

export const returnAdminUser = async (token: string) => {
  const response = await api.get(`users/return-admin-view/${token}`);

  return response.data;
};
