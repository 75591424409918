import React, { useCallback, useState, useEffect, ChangeEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { MdKeyboardBackspace, MdPalette } from 'react-icons/md';

import * as C from 'components';

import { useToast, useLayout } from 'contexts';

import * as S from './styles';

import { ILayoutForm, ILayout } from '../interfaces';

import { getLayout, createLayout, updateLayout } from '../api';

const schema = Yup.object().shape({
  header_title: Yup.string(),
  logo_upload: Yup.mixed(),
  logo_header_upload: Yup.mixed(),
  icon_upload: Yup.mixed(),
});

const LayoutForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();
  const { updateLayout: updateStorageLayout } = useLayout();
  const [loading, setLoading] = useState(false);
  const [layout, setLayout] = useState<ILayout>();
  const history = useHistory();

  const onSubmit = useCallback(
    async (data: ILayoutForm) => {
      try {
        setLoading(true);

        if (layout?.id) {
          const res = await updateLayout(layout.id, data);

          if (res) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            updateStorageLayout({
              header_title: res.header_title || '',
              page_title: res.page_title || '',
              logo: res.logo_url || '',
              logo_header: res.logo_header_url || '',
              icon: res.icon_url || '',
            });

            history.push('/');
          }
        } else {
          await createLayout(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, layout, history, reset],
  );

  useEffect(() => {
    getLayout().then((data) => {
      reset(data);
      setLayout(data);
    });
  }, [reset]);

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/">
              <MdKeyboardBackspace size={24} color="#fff" />
              Configuração
            </Link>
          </S.BackLink>
          <C.HeaderInfo
            title={
              layout?.id
                ? 'Editando: Configuração Layout'
                : 'Configuração Layout'
            }
            icon={MdPalette}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <C.FormGroup>
              <C.Label>Título Página</C.Label>
              <C.Input
                type="text"
                {...register('page_title')}
                errors={errors.page_title}
                name="page_title"
                placeholder="Título página"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Título Topo</C.Label>
              <C.Input
                type="text"
                {...register('header_title')}
                errors={errors.header_title}
                name="header_title"
                placeholder="Título Cabeçalho"
              />
            </C.FormGroup>
          </S.FormContainer>

          <S.IconContainer>
            <C.FormGroup>
              <C.Label>Logo</C.Label>

              <C.Upload
                {...register('logo_upload')}
                imageUrl={layout?.logo_url}
                fileSelected={watch('logo_upload')}
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Logo Topo</C.Label>

              <C.Upload
                {...register('logo_header_upload')}
                imageUrl={layout?.logo_header_url}
                fileSelected={watch('logo_header_upload')}
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Icon</C.Label>

              <C.Upload
                {...register('icon_upload')}
                imageUrl={layout?.icon_url}
                fileSelected={watch('icon_upload')}
              />
            </C.FormGroup>
          </S.IconContainer>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default LayoutForm;
