import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Form from './Form';

const Account: React.FC = () => {
  return (
    <Switch>
      <Route path="/account" component={Form} isPrivate exact />
    </Switch>
  );
};

export default Account;
