import React from 'react';

import { Container } from './styles';

interface Props {
  title: string;
  description?: string;
}

const ToastMsg: React.FC<Props> = ({ title, description }) => {
  return (
    <Container>
      <h3>{title}</h3>
      <p>{description}</p>
    </Container>
  );
};

export default ToastMsg;
