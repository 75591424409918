import React, { ButtonHTMLAttributes } from 'react';
import { IconBaseProps } from 'react-icons';
import * as S from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  icon?: React.ComponentType<IconBaseProps>;
  loading?: boolean;
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-warning'
    | 'outline-danger';
};

interface DefaultProps {
  variant?: string;
}

type PropsWithDefaults = ButtonProps & DefaultProps;

const Button: React.FC<PropsWithDefaults> = ({
  icon: Icon,
  children,
  loading,
  variant = 'primary',
  ...rest
}) => {
  return (
    <S.Container type="button" className={variant} {...rest}>
      {Icon && <Icon size={20} />}
      {loading ? <S.Spinner className="loader" /> : children}
    </S.Container>
  );
};

export default Button;
