import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaTags } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import {
  HeaderBackground,
  HeaderInfo,
  Input,
  Button,
  Label,
  FormGroup,
  Checkbox,
} from 'components';

import { IPaginate } from 'shared/interfaces';

import { useToast } from 'contexts/ToastProvider';

import api from 'services/api';

import { groupBy } from 'utils/groupBy';

import * as S from './styles';

interface FormData {
  name: string;
  reports: string[];
  access: string[];
}

const schema = Yup.object().shape({
  name: Yup.string().required('Permissão obrigatória'),
});

interface Permission {
  id: string;
  name: string;
  reports: [{ id: string; name: string }];
  access: [{ id: string; name: string }];
}

interface PermissionReport {
  id: string;
  name: string;
  category: string;
}

interface PermissionAccess {
  id: string;
  slug: string;
  name: string;
  menu_name: string;
}

interface RouterParams {
  id?: string | undefined;
}

const PermissionForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      // reports: [], problema no checkbox ao ter somente 1 cadastrado
      // access: [], problema no checkbox ao ter somente 1 cadastrado
    },
  });

  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [permission, setPermission] = useState<Permission>();
  const [permissionReport, setPermissionReport] = useState([]);
  const [permissionAccess, setPermissionAccess] = useState([]);
  const { id } = useParams<RouterParams>();
  const history = useHistory();

  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        if (id) {
          const response = await api.put<Permission>(
            `/permissions/${id}`,
            data,
          );

          setPermission(response.data);
        } else {
          let newAccess = data.access;

          if (!data.access) {
            newAccess = [];
          } else if (data.access && typeof data.access === 'string') {
            newAccess = [data.access];
          }

          let newReports = data.reports;

          if (!data.reports) {
            newReports = [];
          } else if (data.reports && typeof data.reports === 'string') {
            newReports = [data.reports];
          }

          await api.post('/permissions', {
            ...data,
            access: newAccess,
            reports: newReports,
          });

          reset();

          history.push('/permissions');
        }
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Salvo com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, history, id, reset],
  );

  useEffect(() => {
    api.get<PermissionReport>('/reports/permissions').then((response) => {
      const { data } = response;

      const perm: any = data; // group by aceita tipo any

      const newPermissionReport = groupBy(perm, 'category');

      setPermissionReport(newPermissionReport);
    });

    api
      .get<IPaginate<PermissionAccess>>('/access', {
        params: { page: 1, per_page: 200 },
      })
      .then((response) => {
        const { data } = response;

        const perm: any = data.data; // group by aceita tipo any

        const newPermissioAccess = groupBy(perm, 'menu_name');

        setPermissionAccess(newPermissioAccess);
      });
  }, []);

  useEffect(() => {
    if (id) {
      api.get<Permission>(`/permissions/${id}`).then((response) => {
        const { data } = response;

        setPermission(data);

        const permissionFormEdit = {
          ...data,
          reports:
            data.reports.length > 0
              ? data.reports.map((report) => report.id)
              : undefined,
          access:
            data.access.length > 0
              ? data.access.map((key) => key.id)
              : undefined,
        };

        reset(permissionFormEdit);
      });
    }
  }, [id, reset]);

  return (
    <S.Container>
      <HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/permissions">
              <MdKeyboardBackspace size={24} color="#fff" />
              Acessos
            </Link>
          </S.BackLink>
          <HeaderInfo
            title={id ? `Editando: ${permission?.name}` : 'Novo Acesso'}
            icon={FaTags}
          />
        </S.HeaderPageDatail>
      </HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Acesso</Label>
            <Input
              {...register('name')}
              type="text"
              errors={errors.name}
              name="name"
              placeholder="Acesso"
            />
          </FormGroup>

          <S.PermissionContainer>
            {Object.entries(permissionAccess).map((item) => (
              <div key={item[0]}>
                <S.TitlePermission>{item[0]}</S.TitlePermission>
                <Checkbox
                  {...register('access')}
                  name="access"
                  items={Array.from(item[1])}
                />
              </div>
            ))}
          </S.PermissionContainer>

          <S.Divider />

          <S.PermissionContainer>
            {Object.entries(permissionReport).map((item) => (
              <div key={item[0]}>
                <S.TitlePermission>{item[0]}</S.TitlePermission>
                <Checkbox
                  {...register('reports')}
                  name="reports"
                  items={Array.from(item[1])}
                />
              </div>
            ))}
          </S.PermissionContainer>

          <Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default PermissionForm;
