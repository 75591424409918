import styled, { css } from 'styled-components';

interface ContainerProps {
  size: number;
}

interface LegendProps {
  color: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;

  ${({ size }) => css`
    height: ${size + 20}px;
  `};
`;

export const Title = styled.div`
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 500;
`;

export const SideLeft = styled.aside`
  padding: 30px 20px;
  width: 35%;

  > h2 {
    margin-bottom: 20px;
  }
`;

export const LegendContainer = styled.ul<ContainerProps>`
  ${({ size }) => css`
    height: ${size - 70}px;
    padding-right: 16px;
    list-style: none;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--primary);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: var(--background-sidebar);
    }
  `}
`;

export const Legend = styled.li<LegendProps>`
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  ${({ color }) => css`
    > div {
      background-color: ${color};
      color: #fff;

      width: 28px;
      height: 28px;
      border-radius: 6px;

      font-size: 12px;
      font-weight: bold;
      line-height: 28px;
      text-align: center;
    }

    > span {
      margin: 0 8px 0 4px;
      font-size: 16px;
      line-height: 28px;
    }
  `}
`;

export const SideRight = styled.main`
  display: flex;
  flex: 1;
  padding: 20px 0;

  justify-content: center;
`;
