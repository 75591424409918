import styled from 'styled-components';

interface IContainerProps {
  color: string;
}

export const Container = styled.div<IContainerProps>`
  padding: 16px;
  height: 150px;
  max-height: 150px;
  background-color: ${(props) =>
    props.color ? props.color : 'var(--background-sidebar)'};

  position: relative;
  overflow: hidden;

  svg {
    position: absolute;
    top: -10px;
    right: -30px;

    opacity: 0.2;
  }

  span {
    display: flex;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
  }

  p {
    display: flex;
    margin-top: 8px;
    font-size: 40px;
  }

  small {
    display: flex;
    font-size: 14px;
    position: absolute;
    bottom: 10px;
  }
`;
