import React, { useEffect, useState } from 'react';

import { ImUsers } from 'react-icons/im';
import { MdSecurity } from 'react-icons/md';

import api from 'services/api';

import { useAuth } from 'contexts';

import { KpiBox } from 'components';

import * as S from './styles';

const DashboardHeader: React.FC = () => {
  const { usersOnline } = useAuth();
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get<number>('/embedded/use/trial');
      setPercent(response.data);
    }

    fetchData();
  }, []);

  return (
    <S.Container>
      <KpiBox
        header="Quantidade"
        body={usersOnline?.length || ''}
        footer={
          usersOnline && usersOnline?.length > 1
            ? 'Usuários Online'
            : 'Usuário Online'
        }
        icon={<ImUsers size={170} />}
      />

      <KpiBox
        header="Token Embedded"
        body={`${percent}%` || ''}
        footer="Percentual de uso"
        icon={<MdSecurity size={170} />}
      />
    </S.Container>
  );
};

export default DashboardHeader;
