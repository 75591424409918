import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';

const customMedia = generateMedia({
  tablet: '1450px',
});

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

export const Content = styled.div`
  display: grid;
  grid-template-areas:
    'table chart1'
    'chart3 chart2';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px 10px;

  ${customMedia.lessThan('tablet')`
    grid-template-columns: 1fr;

    grid-template-areas:
      'table'
      'chart1'
      'chart2'
      'chart3'
  `}
`;

export const CardTable = styled.div`
  grid-area: table;
  background-color: var(--background-sidebar);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px;
`;

export const CardChart1 = styled.div`
  grid-area: chart1;
  background-color: var(--background-sidebar);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px;
`;

export const CardChart2 = styled.div`
  grid-area: chart2;
  background-color: var(--background-sidebar);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px;
`;

export const CardChart3 = styled.div`
  grid-area: chart3;
  background-color: var(--background-sidebar);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px;
`;

export const FilterContainer = styled.div`
  form {
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;
    padding: 0 16px;

    ${customMedia.lessThan('tablet')`
      margin-top: 24px;
    `}
  }
`;

export const ButtonContainer = styled.div`
  margin: 8px 0 0 0;
`;

export const FormGroup = styled.div`
  > label {
    margin-bottom: 4px !important;
  }

  > div {
    margin: 8px 8px 8px 0;
  }
`;

export const Panel = styled.div`
  background: var(--background-panel);
  border-radius: 8px;
  /* position: relative; */
  padding: 10px;
  height: 100%;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px;
`;

export const FooterContainer = styled.div`
  height: 52px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: var(--background);
  padding: 0px 16px;
`;
