import React from 'react';

import { Container } from './styles';

interface Props {
  align?: 'left' | 'center' | 'right';
}

const Position: React.FC<Props> = ({ align = 'center', children, ...rest }) => {
  return (
    <Container {...rest} align={align}>
      {children}
    </Container>
  );
};

export default Position;
