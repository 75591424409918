import React from 'react';

import * as S from './styles';

interface IKpiBoxProps {
  header: string;
  body: string | number;
  footer: string;
  color?: string;
  icon?: JSX.Element;
}

const KpiBox: React.FC<IKpiBoxProps> = ({
  header,
  body,
  footer,
  icon,
  color,
  ...rest
}) => {
  return (
    <S.Container color={color || ''} {...rest}>
      <span>{header}</span>
      <p>{body}</p>
      <small>{footer}</small>
      {icon}
    </S.Container>
  );
};

export { KpiBox };
