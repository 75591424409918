import styled, { css } from 'styled-components';

interface ContainerProps {
  size: number;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  ${({ size }) => css`
    height: ${size + 20}px;
  `};
`;

export const Title = styled.div`
  padding: 30px 20px;
  font-size: 20px;
  font-weight: 500;
`;
