import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import * as S from './styles';

interface ChartBarProps {
  data: {
    name: string;
    value: number;
    percent?: number;
    color?: string;
  }[];

  size?: number;

  title?: string;
}

export const ChartBar: React.FC<ChartBarProps> = ({
  data,
  size = 400,
  title = 'Legenda',
}) => (
  <S.Container size={size}>
    <S.Title>{title}</S.Title>

    <ResponsiveContainer>
      <BarChart
        // width={500}
        // height={300}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        {/* <Legend /> */}
        <Bar label={{ position: 'top' }} dataKey="value" fill="#16a085" />
      </BarChart>
    </ResponsiveContainer>
  </S.Container>
);
