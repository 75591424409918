import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { MdSearch } from 'react-icons/md';
import { FaTags } from 'react-icons/fa';

import {
  InputSearch,
  ButtonLink,
  HeaderBackground,
  HeaderPage,
  HeaderInfo,
  Table,
  Pagination,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Position,
  Button,
  Loader,
  TableResponsive,
  PopUpMenu,
  Can,
} from 'components';

import { ModalHandles } from 'components/Modal';

import api from 'services/api';
import { useToast } from 'contexts/ToastProvider';

import * as S from './styles';

interface Permission {
  id: string;
  name: string;
}

interface Paginate {
  data: Permission[];
  page: number;
  per_page: number;
  last_page: number;
  total: number;
}

const PermissionList: React.FC = () => {
  const [permissions, setPermissions] = useState<Paginate>();
  const [selectedPermission, setSelectedPermission] = useState<Permission>();
  const modalRemoverRef = useRef<ModalHandles>(null);

  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState('');
  const { addToast } = useToast();
  const history = useHistory();

  const handleSearch = useCallback(
    async ({ page, per_page }) => {
      try {
        setLoading(true);

        const params = {
          page,
          per_page,
          term,
        };

        const response = await api.get<Paginate>('/permissions', { params });

        const { data } = response;

        setPermissions(data);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Ops...',
          description: 'Ops, ocorreu um erro no processo de pesquisa',
        });
      } finally {
        setLoading(false);
      }
    },
    [term, addToast],
  );

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 10,
    };

    handleSearch(params);
  }, [handleSearch]);

  const handleRemove = async (): Promise<void> => {
    try {
      await api.delete(`/permissions/${selectedPermission?.id}`);

      if (!permissions) return;
      const newList = permissions.data.filter(
        (item) => item.id !== selectedPermission?.id,
      );

      setPermissions({ ...permissions, data: newList });

      modalRemoverRef.current?.closeModal();

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Permissão removida com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: 'Ocorreu um erro ao inserir um novo registro',
      });
    }
  };

  const handleOpenModalRemove = useCallback(
    (permissionSelected: Permission): void => {
      setSelectedPermission(permissionSelected);
      modalRemoverRef.current?.openModal();
    },
    [],
  );

  return (
    <S.Container>
      <HeaderBackground>
        <HeaderPage>
          <HeaderInfo title="Acessos" icon={FaTags} />

          <S.ContainerSearch>
            <InputSearch
              title="Digite aqui o que deseja pesquisar"
              icon={MdSearch}
              placeholder="Pesquisar"
              onChange={(e) => setTerm(e.target.value)}
              onKeyDown={(e) =>
                e.key === 'Enter'
                  ? handleSearch({ page: 1, per_page: permissions?.per_page })
                  : {}
              }
            />
          </S.ContainerSearch>

          <div>
            <Can checkAccess="permissions_create">
              <ButtonLink title="Adicionar Permissão" to="/permissions/add">
                Adicionar
              </ButtonLink>
            </Can>
          </div>
        </HeaderPage>
      </HeaderBackground>

      <S.Panel>
        {loading ? (
          <Loader loading={loading} align="center" message="Carregando..." />
        ) : (
          <>
            <TableResponsive>
              <Table>
                <thead>
                  <tr>
                    <th className="text-left">Acesso</th>
                    <th className="text-center">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {permissions &&
                    permissions.data.map((item: Permission) => (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td className="text-center">
                          <PopUpMenu>
                            <Can checkAccess="permissions_update">
                              <button
                                type="button"
                                onClick={() =>
                                  history.push(`/permissions/edit/${item.id}`)
                                }
                              >
                                <S.IconEdit
                                  title="Editar"
                                  size={24}
                                  color="#707070"
                                />
                                Editar
                              </button>
                            </Can>

                            <Can checkAccess="permissions_delete">
                              <button
                                type="button"
                                onClick={() => handleOpenModalRemove(item)}
                              >
                                <S.IconDelete
                                  title="Remover"
                                  size={24}
                                  color="#707070"
                                />
                                Remover
                              </button>
                            </Can>
                          </PopUpMenu>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </TableResponsive>
            <S.FooterContainer>
              {permissions && (
                <Pagination
                  page={permissions?.page}
                  total={permissions?.total}
                  per_page={permissions?.per_page}
                  last_page={permissions?.last_page}
                  onSearch={handleSearch}
                />
              )}
            </S.FooterContainer>
          </>
        )}
      </S.Panel>

      <Modal ref={modalRemoverRef} options={{ width: '400px' }}>
        <ModalHeader>
          <ModalTitle>Você Confirma a EXCLUSÃO?</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>O registro será removido completamente!</p>
        </ModalBody>
        <ModalFooter>
          <Position align="right">
            <Button
              variant="outline-secondary"
              onClick={() => modalRemoverRef.current?.closeModal()}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => handleRemove()}
              style={{ marginLeft: '16px' }}
            >
              SIM, confirmo a exclusão
            </Button>
          </Position>
        </ModalFooter>
      </Modal>
    </S.Container>
  );
};

export default PermissionList;
