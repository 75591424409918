import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';

import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { models, IEmbedConfiguration, service } from 'powerbi-client';
import 'powerbi-report-authoring';

import api from '../../services/api';

import * as S from './styles';
import './style.css';

interface RouterParams {
  id?: string | undefined;
  report?: string | undefined;
}

const Embedded: React.FC = () => {
  const { id, report } = useParams<RouterParams>();
  const [loading, setLoading] = useState(false);
  const screen1 = useFullScreenHandle();

  const [reportConfig, setReportConfig] = useState<IEmbedConfiguration>({
    type: 'report',
    id: report,
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: '',
    settings: {
      panes: {
        // pageNavigation: {
        //   visible: false,
        // },
        filters: {
          expanded: false,
          visible: true,
        },
      },
      // background: models.BackgroundType.Transparent,
    },
  });

  const embeddedToken = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`embedded/${id}`);

      setReportConfig({
        ...reportConfig,
        id: report,
        accessToken: response.data.token,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    embeddedToken();
  }, [embeddedToken]);

  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap = new Map([
    [
      'loaded',
      () => {
        // Quando relatorio esta carregando
        //  console.log('Report has loaded');
      },
    ],
    [
      'rendered',
      () => {
        // Quando o relatorio finaliza
        // console.log('The report is rendered');
      },
    ],
    [
      'error',
      (event?: service.ICustomEvent<any>) => {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
  ]);

  return (
    <FullScreen handle={screen1}>
      <S.Container>
        {screen1.active ? (
          <S.IconContainer onClick={() => screen1.exit()}>
            <MdClose size={24} color="#fff" />
          </S.IconContainer>
        ) : (
          <S.IconContainer onClick={() => screen1.enter()}>
            <BsArrowsFullscreen size={24} color="#fff" />
          </S.IconContainer>
        )}

        {!loading && (
          <S.Embedded
            embedConfig={reportConfig}
            eventHandlers={eventHandlersMap}
            cssClassName={screen1.active ? 'report-class-full' : 'report-class'}
            // getEmbeddedComponent={(embedObject: Embed) => {
            //   console.log(
            //     `Embedded object of type "${embedObject.embedtype}" received`,
            //   );
            // }}
          />
        )}
      </S.Container>
    </FullScreen>
  );
};

export default Embedded;
