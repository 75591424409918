import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { renderCustomizedLabel } from './label';

import * as S from './styles';

interface ChartPieProps {
  data: {
    name: string;
    value: number;
    percent?: number;
    color?: string;
  }[];

  size?: number;

  title?: string;
}

export const ChartPie: React.FC<ChartPieProps> = ({
  data,
  size = 400,
  title = 'Legenda',
}) => (
  <S.Container size={size}>
    <S.SideLeft>
      <S.Title>{title}</S.Title>

      <S.LegendContainer size={size}>
        {data.map((entry, idx: number) => (
          <S.Legend key={String(idx)} color={entry.color || '#fff'}>
            <div>{entry.value}</div>
            <span>{entry.name}</span>
          </S.Legend>
        ))}
      </S.LegendContainer>
    </S.SideLeft>

    <S.SideRight>
      <ResponsiveContainer>
        <PieChart>
          <Pie data={data} dataKey="value" label={renderCustomizedLabel}>
            {data.map((entry, idx: number) => (
              <Cell key={String(idx)} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </S.SideRight>
  </S.Container>
);
