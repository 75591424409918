import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiMail, FiLock } from 'react-icons/fi';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { useAuth, useToast, useLayout } from 'contexts';
import { getLayoutLogin } from 'pages/Config/api';

import * as C from 'components';
import defaultImg from 'assets/logo.png';
import * as S from './styles';

interface SignInFormData {
  email: string;
  password: string;
}

const schema = Yup.object().shape({
  email: Yup.string()
    .required('Email obrigatório')
    .email('Digite um e-mail válido'),
  password: Yup.string().required('Senha obrigatória'),
});

const SignInAdmin: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormData>({
    resolver: yupResolver(schema),
  });

  const { signIn } = useAuth();
  const { layout, updateLayout } = useLayout();
  const { addToast } = useToast();

  const logoImg = layout?.logo ? layout.logo : defaultImg;

  useEffect(() => {
    getLayoutLogin().then((data) => {
      if (data.id) {
        updateLayout({
          header_title: data.header_title || '',
          page_title: data.page_title || '',
          logo: data.logo_url || '',
          logo_header: data.logo_header_url || '',
          icon: data.icon_url || '',
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro na autenricação',
          description:
            'Ocorreu um erro ao fazer login, verifique as credenciais EMAIL / SENHA',
        });
      }
    },
    [signIn, addToast],
  );

  return (
    <S.Container>
      <S.Content>
        <S.AnimationContainer>
          <img src={logoImg} alt="A1 Analytics" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1>Faça seu logon</h1>

            <C.Input
              {...register('email')}
              type="text"
              name="email"
              icon={FiMail}
              placeholder="E-mail"
              errors={errors.email}
            />
            <C.Input
              {...register('password')}
              type="password"
              name="password"
              icon={FiLock}
              placeholder="Senha"
              autoComplete="off"
              errors={errors.password}
            />

            <C.Button variant="primary" type="submit">
              Entrar
            </C.Button>

            <Link to="/forgot-password">Esqueci minha senha</Link>
          </form>

          {/* <Link to="/signup">
            <FiLogIn />
            Criar conta
          </Link> */}
        </S.AnimationContainer>
      </S.Content>
      <S.Background />
    </S.Container>
  );
};

export default SignInAdmin;
