import React from 'react';

import { useSiedeBar } from 'contexts/SideBarProvider';

import { Container } from './styles';

const Content: React.FC = ({ children }) => {
  const { isOpened } = useSiedeBar();

  return <Container isMenuOpened={isOpened}>{children}</Container>;
};

export default Content;
