import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';

const media = generateMedia({
  tablet: '900px',
});

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
  padding: 8px;

  ${media.lessThan('tablet')`
    grid-template-columns: repeat(1, 1fr)
  `}
`;
