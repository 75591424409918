import styled, { css } from 'styled-components';
import { MdEdit, MdDelete } from 'react-icons/md';
import { AiFillEye } from 'react-icons/ai';

export const Container = styled.div``;

export const ContainerSearch = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 16px;

  & > div {
    max-width: 400px;
    transition: max-width 0.2s;
  }
  & > div:hover {
    max-width: 500px;
  }
`;

export const Panel = styled.div`
  background: var(--background-panel);
  top: -50px;
  border-radius: 8px;
  position: relative;
  margin: 0px 32px 0px 32px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px;
`;

export const FooterContainer = styled.div`
  height: 52px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: var(--background);
  padding: 0px 16px;
`;

const IconCss = css`
  flex-shrink: 0;
  padding: 4px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;

  &:hover {
    background: #ffffff12;
  }
`;

export const IconEdit = styled(MdEdit)`
  ${IconCss}
`;
export const IconDelete = styled(MdDelete)`
  ${IconCss}
`;

export const IconView = styled(AiFillEye)`
  ${IconCss}
`;

export const FooterModal = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: yellow;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button:first-child {
    margin-right: 8px;
    margin-top: 0;
  }
`;
