// eslint-disable-next-line import/no-duplicates
import { parseISO, format } from 'date-fns';

// eslint-disable-next-line import/no-duplicates
import pt from 'date-fns/locale/pt-BR';

// eslint-disable-next-line no-shadow
export enum CustomFormats {
  dd_MM_yyyy = 'dd/MM/yyyy',
  ddMMMyyyy = 'dd MMM yyyy',
  HH_mm = 'HH:mm',
  HHh_mm = "HH'h' mm",
}

export const toFormatDate = (
  date: string | Date,
  customFormat = CustomFormats.dd_MM_yyyy,
) => {
  if (!date) return '';

  const currentDate = typeof date === 'object' ? date : parseISO(date);

  if (!(typeof currentDate === 'object')) return '';

  const dateFormatted = format(currentDate, customFormat, {
    locale: pt,
  });

  return dateFormatted;
};
