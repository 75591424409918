import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { Item, LabelMenu } from './styles';

interface Menu {
  name: string;
  route: string;
  icon?: ReactElement | null;
  children: Menu[];
}

interface Props {
  menu: Menu;
}

const ItemSubMenu: React.FC<Props> = ({ menu }) => {
  return (
    <>
      <Item>
        <NavLink to={menu.route} activeClassName="sideBarLinkActive">
          {menu.icon}
          <LabelMenu>{menu.name}</LabelMenu>
        </NavLink>
      </Item>
    </>
  );
};

export default ItemSubMenu;
