import React from 'react';

import { useAuth } from 'contexts';

import { dynamicSort } from 'utils/sort';

import avartarImgDefault from '../../../../assets/avatar.png';

import * as S from './styles';

const DashboardBody: React.FC = () => {
  const { usersOnline } = useAuth();

  return (
    <S.Container>
      <S.UsersOnlineContainer>
        {usersOnline
          .map((user) => (
            <S.UsersOnline key={String(user.user_id)}>
              <img
                src={
                  user?.avatar_url !== 'null'
                    ? user.avatar_url
                    : avartarImgDefault
                }
                alt="Icon"
              />

              <S.UserOnline>
                <S.UserLine>
                  <S.Name>{user.user_name}</S.Name>
                </S.UserLine>
              </S.UserOnline>
            </S.UsersOnline>
          ))
          .sort(dynamicSort('user_name'))}
      </S.UsersOnlineContainer>
    </S.Container>
  );
};

export default DashboardBody;
