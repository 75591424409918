import React, { createContext, useCallback, useState, useContext } from 'react';

import { ILayoutContextData, ILayoutState, ILayout } from './types';

const AuthContext = createContext<ILayoutContextData>({} as ILayoutContextData);

const LayoutProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<ILayoutState>(() => {
    const layout = localStorage.getItem('@a1Analytics:layout');

    if (layout) {
      return { layout: JSON.parse(layout) };
    }

    return {} as ILayoutState;
  });

  const updateLayout = useCallback(
    (layout: ILayout) => {
      setData({
        ...data,
        layout,
      });

      localStorage.setItem('@a1Analytics:layout', JSON.stringify(layout));
    },
    [data],
  );

  return (
    <AuthContext.Provider
      value={{
        layout: data.layout,
        updateLayout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useLayout(): ILayoutContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useLayout must be used within an AuthProvider');
  }

  return context;
}

export { LayoutProvider, useLayout };
