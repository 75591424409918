import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';

const media = generateMedia({
  tablet: '900px',
});

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
  padding: 8px;

  ${media.lessThan('tablet')`
    grid-template-columns: repeat(1, 1fr)
  `}
`;

export const UsersOnlineContainer = styled.div`
  background-color: var(--background-sidebar);
  max-height: 400px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track-piece {
    background: var(--scrollbar); /* Cor  fundo scroll*/
  }

  ::-webkit-scrollbar-thumb {
    background: var(--scrollbar);
  }
`;

export const UsersOnline = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 70px;

  > img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: 15px;
  }
`;

export const UserOnline = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  border-bottom: 1px solid var(--border);
  padding-right: 15px;
  margin-left: 15px;

  flex-wrap: wrap;
  min-width: 0;
`;

export const UserLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Name = styled.div`
  color: var(--primary);
  font-size: 17px;
  font-weight: 500;
`;
