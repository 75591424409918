import React, { useCallback, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaTags } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import {
  HeaderBackground,
  HeaderInfo,
  Input,
  Button,
  Label,
  FormGroup,
} from 'components';

import { useToast } from 'contexts/ToastProvider';

import api from 'services/api';

import * as S from './styles';

interface FormData {
  email: string;
  password: string;
  client_id: string;
  client_secret: string;
}

const schema = Yup.object().shape({
  email: Yup.string()
    .required('Email obrigatório')
    .email('Digite um e-mail válido'),
  password: Yup.string().required('Senha obrigatória'),
});
interface Account {
  id: string;
  email: string;
  password: string;
  client_id: string;
  client_secret: string;
}

const AccountForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState<Account>();
  const history = useHistory();

  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        if (account?.id) {
          const response = await api.put<Account>(
            `/accounts/${account?.id}`,
            data,
          );

          setAccount(response.data);
        } else {
          await api.post('/accounts', data);
          reset();

          history.push('/');
        }

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Salvo com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    [account?.id, addToast, history, reset],
  );

  useEffect(() => {
    api.get<Account>(`/accounts/tenant`).then((response) => {
      const { data } = response;
      reset(data);
      setAccount(data);
    });
  }, [reset]);

  return (
    <S.Container>
      <HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/">
              <MdKeyboardBackspace size={24} color="#fff" />
              Conta PowerBI
            </Link>
          </S.BackLink>
          <HeaderInfo
            title={
              account?.id ? `Editando: ${account?.email}` : 'Configuração conta'
            }
            icon={FaTags}
          />
        </S.HeaderPageDatail>
      </HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Email</Label>
            <Input
              {...register('email')}
              type="text"
              errors={errors.email}
              name="email"
              placeholder="Email"
            />
          </FormGroup>

          <FormGroup>
            <Label>Senha</Label>
            <Input
              {...register('password')}
              type="password"
              errors={errors.password}
              name="password"
              placeholder="Senha"
              autoComplete="off"
            />
          </FormGroup>

          <FormGroup>
            <Label>Client ID</Label>
            <Input
              {...register('client_id')}
              type="text"
              errors={errors.client_id}
              name="client_id"
              placeholder="Client ID"
            />
          </FormGroup>

          <FormGroup>
            <Label>Client Secret</Label>
            <Input
              {...register('client_secret')}
              type="text"
              errors={errors.client_secret}
              name="client_secret"
              placeholder="Client secret"
            />
          </FormGroup>

          <Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default AccountForm;
