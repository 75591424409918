import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Home } from './Home';
import { SessionResume } from './SessionResume';

const Dashboard: React.FC = () => {
  return (
    <Switch>
      <Route path="/dashboard" component={Home} isPrivate exact />
      <Route
        path="/dashboard/session/resume"
        component={SessionResume}
        isPrivate
      />
    </Switch>
  );
};

export default Dashboard;
