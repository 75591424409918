import { IPaginate, IParams } from 'shared/interfaces';

import api from 'services/api';

import { IRls, IRlsForm } from './interfaces';

const getRls = async (id: string): Promise<IRls> => {
  const rls = await api.get<IRls>(`/rls/${id}`);

  return rls.data;
};

const getRlss = async (params: IParams): Promise<IPaginate<IRls>> => {
  const response = await api.get<IPaginate<IRls>>('/rls', {
    params,
  });

  return response.data;
};

const createRls = async (rls: IRlsForm): Promise<void> => {
  await api.post('/rls', rls);
};

const updateRls = async (id: string, rls: IRlsForm): Promise<IRls> => {
  const response = await api.put<IRls>(`/rls/${id}`, rls);

  return response.data;
};

const deleteRls = async (id: string): Promise<void> => {
  await api.delete(`/rls/${id}`);
};

export { getRls, getRlss, createRls, updateRls, deleteRls };
