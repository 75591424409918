import { IPaginate, IParams } from 'shared/interfaces';

import api from 'services/api';

import { IUser, IUserForm } from './interfaces';

const createFormDataReport = async (user: IUserForm) => {
  const rls = user.rls_users?.map((item) => {
    return item.value;
  });

  const permission = user.permission_users?.map((item) => {
    return item.value;
  });

  const data = {
    ...user,
    permission_users: permission || [],
    rls_users: rls || [],
  };

  return data;
};

const getUser = async (id: string): Promise<IUser> => {
  const user = await api.get<IUser>(`/users/${id}`);

  const data: IUser = {
    ...user.data,
    permission_users:
      user.data?.permission_users?.length > 0
        ? user.data?.permission_users?.map((item) => {
            return {
              label: String(item.name) || '',
              value: item.id || '',
            };
          })
        : [],
    rls_users:
      user.data?.rls_users?.length > 0
        ? user.data?.rls_users?.map((item) => {
            return {
              label: String(item.name) || '',
              value: item.id || '',
            };
          })
        : [],
  };

  return data;
};

const getUsers = async (params: IParams): Promise<IPaginate<IUser>> => {
  const response = await api.get<IPaginate<IUser>>('/users', {
    params,
  });

  const dataWithFormattedAccess = {
    ...response.data,
    data: response.data.data.map((item) => ({
      ...item,
      acesso_formatado:
        item?.permission_users?.length > 0
          ? item.permission_users
              .map((permission) => String(permission.name))
              .join(', ')
          : '',
    })),
  };

  return dataWithFormattedAccess;
};

const createUser = async (user: IUserForm): Promise<void> => {
  const data = await createFormDataReport(user);

  await api.post('/users', data);
};

const updateUser = async (id: string, user: IUserForm): Promise<IUser> => {
  const data = await createFormDataReport(user);

  const response = await api.put<IUser>(`/users/${id}`, data);

  return response.data;
};

const deleteUser = async (id: string): Promise<void> => {
  await api.delete(`/users/${id}`);
};

export { getUser, getUsers, createUser, updateUser, deleteUser };
