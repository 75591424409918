import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaTags } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import {
  HeaderBackground,
  HeaderInfo,
  Input,
  Button,
  Label,
  FormGroup,
} from 'components';

import { useToast } from 'contexts/ToastProvider';

import api from 'services/api';

import * as S from './styles';

interface FormData {
  name: string;
}

const schema = Yup.object().shape({
  name: Yup.string().required('Categoria obrigatório'),
});

interface Category {
  id: string;
  name: string;
}

interface RouterParams {
  id?: string | undefined;
}

const CategoryForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<Category>();
  const { id } = useParams<RouterParams>();
  const history = useHistory();

  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);

        if (id) {
          const response = await api.put<Category>(`/categories/${id}`, data);

          setCategory(response.data);
        } else {
          await api.post('/categories', data);
          reset();

          history.push('/categories');
        }

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Salvo com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, history, id, reset],
  );

  useEffect(() => {
    if (id) {
      api.get<Category>(`/categories/${id}`).then((response) => {
        const { data } = response;
        reset(data);
        setCategory(data);
      });
    }
  }, [id, reset]);

  return (
    <S.Container>
      <HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/categories">
              <MdKeyboardBackspace size={24} color="#fff" />
              Categorias
            </Link>
          </S.BackLink>
          <HeaderInfo
            title={id ? `Editando: ${category?.name}` : 'Nova Categoria'}
            icon={FaTags}
          />
        </S.HeaderPageDatail>
      </HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Categoria</Label>
            <Input
              {...register('name')}
              type="text"
              errors={errors.name}
              name="name"
              placeholder="Categoria"
            />
          </FormGroup>

          <Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default CategoryForm;
