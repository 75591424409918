export const dynamicSort = (field: any): any => {
  let sortOrder = 1;
  let property = field;

  if (field[0] === '-') {
    sortOrder = -1;
    property = field.substr(1);
  }

  const order = (a: string, b: string): number => {
    // const result =
    //   a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;

    let result = 0;

    if (a[property] < b[property]) {
      result = -1;
    } else if (a[property] > b[property]) {
      result = 1;
    } else {
      result = 0;
    }

    return result * sortOrder;
  };

  return order;
};
