import React from 'react';
import { useAuth } from 'contexts';

interface ICheckAuthProps {
  access: string[];
  checkRole: string;
  checkAccess: string;
}

interface ICanProps {
  checkAccess: string;
  children: JSX.Element;
}

const checkAuth = ({
  access,
  checkRole,
  checkAccess,
}: ICheckAuthProps): boolean => {
  if (checkRole === 'admin') return true;

  if (!access.includes(checkAccess)) return false;

  return true;
};

const Can = ({ children, checkAccess }: ICanProps) => {
  const { access, user } = useAuth();

  const canPermission = checkAuth({
    access,
    checkRole: user.role,
    checkAccess,
  });

  if (canPermission) {
    return <>{children}</>;
  }

  return <></>;

  // return typeof children === 'function'
  //   ? checkAuth({ permissions, checkRole: user.role, checkPermission })
  //   : checkAuth({ permissions, checkRole: user.role, checkPermission }) &&
  //       children;
};

export { Can };
