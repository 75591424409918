import React from 'react';

import { Container } from './styles';

interface TableResponsiveProps {
  height?: string;
}

const TableResponsive: React.FC<TableResponsiveProps> = ({
  height = 'auto',
  children,
}) => {
  return <Container height={height}>{children}</Container>;
};

export default TableResponsive;
