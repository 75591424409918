import { IPaginate, IParams } from 'shared/interfaces';

import api from 'services/api';

import { IWorkspace, IEmbeddedWorkspace, IWorkspaceForm } from './interfaces';

const createFormDataWorkspace = (workspace: IWorkspaceForm) => {
  const data = {
    ...workspace,
    workspace_id: workspace.workspace_id?.value || null,
    workspace_name: workspace.workspace_id?.label || null,
  };

  return data;
};

const getWorkspace = async (id: string): Promise<IWorkspace> => {
  const workspace = await api.get<IWorkspace>(`/workspaces/${id}`);

  const data: IWorkspace = {
    ...workspace.data,
    workspace_id: workspace.data?.workspace_id
      ? {
          label: String(workspace.data?.workspace_name) || '',
          value: String(workspace.data?.workspace_id) || '',
        }
      : '',
  };

  return data;
};

const getWorkspaces = async (
  params: IParams,
): Promise<IPaginate<IWorkspace>> => {
  const response = await api.get<IPaginate<IWorkspace>>('/workspaces', {
    params,
  });

  return response.data;
};

const createWorkspace = async (workspace: IWorkspaceForm): Promise<void> => {
  const data = createFormDataWorkspace(workspace);

  await api.post('/workspaces', data);
};

const updateWorkspace = async (
  id: string,
  workspace: IWorkspaceForm,
): Promise<IWorkspace> => {
  const data = createFormDataWorkspace(workspace);

  const response = await api.put<IWorkspace>(`/workspaces/${id}`, data);

  return response.data;
};

const deleteWorkspace = async (id: string): Promise<void> => {
  await api.delete(`/workspaces/${id}`);
};

const getEmbeddedWorkspaces = async (
  term: string,
): Promise<IEmbeddedWorkspace> => {
  const embeddedWorkspace = await api.get<IEmbeddedWorkspace>(
    '/embedded/group/workspaces',
    { params: { term } },
  );

  return embeddedWorkspace.data;
};

export {
  getWorkspace,
  getWorkspaces,
  createWorkspace,
  updateWorkspace,
  deleteWorkspace,
  getEmbeddedWorkspaces,
};
