import React from 'react';

import { Header, SideBar } from 'components';
import { SiedeBarProvider } from 'contexts/SideBarProvider';

import Content from './Content';
import { Container, Main } from './styles';

const PrivateLayout: React.FC = ({ children }) => {
  return (
    <Container>
      <SiedeBarProvider>
        <Header />
        <Main>
          <SideBar />
          <Content>{children}</Content>
        </Main>
      </SiedeBarProvider>
    </Container>
  );
};

export default PrivateLayout;
