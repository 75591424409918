import React from 'react';

import { AuthProvider } from '../AuthProvider';
import { ToastProvider } from '../ToastProvider';
import { ThemeProvider } from '../ThemeProvider';
import { LayoutProvider } from '../LayoutProvider';
import { SocketProvider } from '../SocketProvider';

export const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ThemeProvider>
      <LayoutProvider>
        <SocketProvider>
          <ToastProvider>{children}</ToastProvider>
        </SocketProvider>
      </LayoutProvider>
    </ThemeProvider>
  </AuthProvider>
);
