import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaTags } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';

import { IRouterParams } from 'shared/interfaces';

import { useToast } from 'contexts/ToastProvider';

import * as C from 'components';

import { getRls, createRls, updateRls } from '../api';

import { IRls, IRlsForm } from '../interfaces';

import * as S from './styles';

const schema = Yup.object().shape({
  name: Yup.string().required('Rls obrigatório'),
  slug: Yup.string().required('Slug obrigatório'),
});

const RlsForm: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const { id } = useParams<IRouterParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [rls, setRls] = useState<IRls>();

  const onSubmit = useCallback(
    async (data: IRlsForm) => {
      try {
        setLoading(true);

        if (id) {
          const response = await updateRls(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/rls');
          }
        } else {
          await createRls(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/rls');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, history, id, reset],
  );

  useEffect(() => {
    if (id) {
      getRls(id).then((data) => {
        reset(data);
        setRls(data);
      });
    }
  }, [id, reset]);

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/rls">
              <MdKeyboardBackspace size={24} color="#fff" />
              Rls
            </Link>
          </S.BackLink>
          <C.HeaderInfo
            title={id ? `Editando: ${rls?.name}` : 'Nova Rls'}
            icon={FaTags}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <C.FormGroup>
            <C.Label>Rls</C.Label>

            <C.Input
              {...register('name')}
              type="text"
              errors={errors.name}
              name="name"
              placeholder="Rls"
            />
          </C.FormGroup>

          <C.FormGroup>
            <C.Label>Slug</C.Label>

            <C.Input
              {...register('slug')}
              type="text"
              errors={errors.slug}
              name="slug"
              placeholder="Rls"
            />
          </C.FormGroup>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default RlsForm;
