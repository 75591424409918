import { IPaginate, IParams } from 'shared/interfaces';

import api from 'services/api';

import { ICategory } from './interfaces';

const getCategories = async (
  params: IParams,
): Promise<IPaginate<ICategory>> => {
  const response = await api.get<IPaginate<ICategory>>('/categories', {
    params,
  });

  return response.data;
};

export { getCategories };
