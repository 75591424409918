import React from 'react';
import { PieLabel } from 'recharts';

import colors from 'styles/colors';

const renderCustomizedLabel: PieLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  //  index,
}) => {
  // https://codesandbox.io/s/rechart-pie-chart-w-custom-label-example-hzefi?file=/Hello.js:245-1368

  const RADIAN = Math.PI / 180;
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill={colors.text}
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
};

export { renderCustomizedLabel };
