import React from 'react';

import { Can } from 'components';

import Header from './Header';
import Body from './Body';

import * as S from './styles';

const SessionResume: React.FC = () => {
  return (
    <Can checkAccess="dashboard_list">
      <S.Container>
        <Header />
        <Body />
      </S.Container>
    </Can>
  );
};

export { SessionResume };
