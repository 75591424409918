import React, { useCallback, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaTags } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import { OptionTypeBase } from 'react-select';

import * as C from 'components';

import { useToast } from 'contexts/ToastProvider';

import { onDelay } from 'utils/debounce';
import { IOptionReactSelect } from 'shared/interfaces';

import api from 'services/api';
import { getPermissions } from 'pages/Permission/api';

import * as S from './styles';

import { IConfigForm, IConfig } from '../interfaces';

import { getConfig, createConfig, updateConfig } from '../api';

const schema = Yup.object().shape({
  permission_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Permissão obrigatoria')
    .typeError('Permissão obrigatoria'),
});

const ConfigForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState<IConfig>();
  const history = useHistory();

  const loadOptionsPermissions = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getPermissions({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const onSubmit = useCallback(
    async (data: IConfigForm) => {
      try {
        setLoading(true);

        if (config?.id) {
          const res = await updateConfig(config.id, data);

          if (res) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/');
          }
        } else {
          await createConfig(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, config, history, reset],
  );

  useEffect(() => {
    getConfig().then((data) => {
      reset(data);
      setConfig(data);
    });
  }, [reset]);

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/">
              <MdKeyboardBackspace size={24} color="#fff" />
              Configuração
            </Link>
          </S.BackLink>
          <C.HeaderInfo
            title={
              config?.id ? 'Editando: Configuração conta' : 'Configuração conta'
            }
            icon={FaTags}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <C.FormGroup>
            <C.Label>Acesso padrão</C.Label>
            <C.ReactSelectAsync
              {...register('permission_id')}
              control={control}
              errors={errors.permission_id}
              loadOptions={loadOptionsPermissions}
              isClearable
              isSearchable
              loadingMessage="Buscando..."
              noOptionsMessage="Nenhum registro localizado"
            />
          </C.FormGroup>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

export default ConfigForm;
