import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Form from './Form';

const Rls: React.FC = () => {
  return (
    <Switch>
      <Route path="/rls" component={List} isPrivate exact />
      <Route path="/rls/add" component={Form} isPrivate />
      <Route path="/rls/edit/:id" component={Form} isPrivate />
    </Switch>
  );
};

export default Rls;
