import React from 'react';
import { IconBaseProps } from 'react-icons';
import { LinkProps, Link } from 'react-router-dom';

import { Container } from './styles';

type ButtonLinkProps = LinkProps<HTMLLinkElement> & {
  icon?: React.ComponentType<IconBaseProps>;
  to: string;
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-warning'
    | 'outline-danger';
};

interface DefaultProps {
  variant?: string;
}

type PropsWithDefaults = LinkProps & DefaultProps & ButtonLinkProps;

const ButtonLink: React.FC<PropsWithDefaults> = ({
  children,
  to,
  icon: Icon,
  variant = 'primary',
  ...rest
}) => {
  return (
    <Container className={variant}>
      <Link to={to} {...rest}>
        {Icon && <Icon size={20} />}
        {children}
      </Link>
    </Container>
  );
};

export default ButtonLink;
